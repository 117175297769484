import React from 'react'

import Layout from 'layouts/Page'
import SEO from 'components/shared/SEO'

const HowItWorksPage = () => <div />

export default ({ location }) => (
  <Layout navbar="main" location={location}>
    <SEO title="How it works" />
    <HowItWorksPage />
  </Layout>
)
